<template>
  <div id="selcat">
    <treeselect
      v-model="value"
      :multiple="false"
      :options="familias"
      :load-options="loadOptions"
      v-on:input="cambioCategoria"
      noChildrenText="No se encontraron subcategorías"
      placeholder="Seleccione una categoría"
      cleareable=1
      clearValueText="Eliminar selección"
    ></treeselect>

    <alert
      v-bind:tipo="notificacion.tipo"
      v-bind:mensaje="notificacion.mensaje"
    ></alert>
  </div>
</template>



<script>
const proto_nodo = {
  id: 0,
  label: "",
  children: null,
};

const proto_nodo_hoja = {
  id: 0,
  label: "",
};

import familianivelservice from "@/services/FamiliaNivelServices";
import Treeselect from "@riophae/vue-treeselect";
import { LOAD_CHILDREN_OPTIONS } from "@riophae/vue-treeselect";
import alert from "@/components/alert/alert.vue";
export default {
  name: "ListarFamiliaNivel",
  components: { alert, Treeselect },
  data() {
    return {
      isLoading: false,
      notificacion: { mensaje: "", tipo: "error" },
      value: null,
      familias: [],
      familia: familianivelservice.Familia,
      familiasSelect: [],
      buscarfamilia: "",
      options: [
        //este es el ejemplo del sitio
        {
          id: "a",
          label: "a",
          children: [
            {
              id: "a/aa",
              label: "aa",
              children: null,
            },
            {
              id: "a/ab",
              label: "ab",
            },
          ],
        },
        {
          id: "b",
          label: "b",
        },
        {
          id: "c",
          label: "c",
        },
      ],
    };
  },
  created() {
    this.CargarFamilia();
  },
  computed: {
    getFamilias() {
      return this.familias;
    },
  },
  methods: {
    CargarFamilia() {
      this.familias = [];
      this.isLoading = true;
      this.notificacion.mensaje = "";
      familianivelservice
        .getAll()
        .then((res) => {
          this.familias = this.formatearNodos(res, 0, null);
        })
        .catch((err) => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar las familias.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadOptions({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        //callback(new Error(parentNode.id));
        let codigos_array = parentNode.id.split("/");

        familianivelservice
          .consultarPorArray(codigos_array)
          .then((res) => {
            parentNode.children = this.formatearNodos(
              res,
              Object.keys(codigos_array).length,
              parentNode.id
            );
            callback();
          })
          .catch((err) => {
            //no se como validar un error interno, ejemplo lanzado desde this.formatearNodos
            if (
              err.response &&
              err.response.data.hasOwnProperty("errorMessage")
            ) {
              callback(new Error(err.response.data.errorMessage));
            } else {
              callback(
                new Error(
                  "Ocurrio un error al intentar recuperar las familias."
                )
              );
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    formatearNodos(result, nivel, idPadre) {
      let ret = [];

      result.forEach((value, index) => {
        //console.log(value);
        console.log(index);

        if (value.fam_familia != "--") {
          let obj = null;
          if (nivel == 3) {
            // console.log("ultimo");
            obj = Object.create(proto_nodo_hoja);
          } else {
            // console.log("tiene hijos");
            obj = Object.create(proto_nodo);

            obj.children = null;
          }

          let id = "";
          if (idPadre !== null) {
            id = idPadre + "/" + value.fam_familia;
          } else {
            id = value.fam_familia;
          }

          obj.id = id;
          obj.label = value.fam_descripcion;
          ret.push(obj);
        }
      });

      return ret;
      // [
      //     {
      //         id: "a",
      //         label: "a",
      //         children: null <<<< esto es solo si puede tener hijos ese nodo
      //     }
      // ]
    },
    cambioCategoria(node) {
        //console.log(node);
        //ahora esta llamado desde un v-on:input, solamente trae el valor seleccionado
        //la otra opcion era v-on:select traia todo el nodo, pudiendo ver el parent y todo
        //pero esa opcion no le funcionaba el clear cuando haces clic en la crucecita
        this.$bus.$emit("selectTreeFamilianivelChange", node);
    }
  },
};
</script>