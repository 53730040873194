import api from "./api";

const marcaService = {};
// let Marca = {
//     marcas_codigo: 0,
//     marcas_descripcion: "Sin definir",
//     marcas_orden: 0,
//     marcas_estado: 0
// };

marcaService.getById = async function(id) {
    return api.get('/Marca/' + id).then(res => res.data);
}

marcaService.getAll = async function() {
    return api.get("/Marca/").then(res => res.data);
};


export default marcaService;