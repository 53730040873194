import api from "./api";

const ArticuloService = {};
let Articulo = {
    art_codigo: 0,
    art_articu: "Sin definir",
    art_descri: "Sin definir",
    sto_stoact: 0,
    sto_umint: 0,
    med_descri: "Sin definir",
    stockcritico: 0,
    fam_famil1: "Sin definir",
    fam_famil2: "Sin definir",
    fam_famil3: "Sin definir",
    fam_famil4: "Sin definir",
    fam_descri: "Sin definir",
    fam_descripcion: "Sin definir",
    des_Fam_1: "Sin definir",
    des_Fam_2: "Sin definir",
    des_Fam_3: "Sin definir",
    aiv_descri: "Sin definir",
    aiv_porcen: 0,
    precio: 0,
    precioFinal: 0,
    li1_fecdes: "Sin definir",
    ar4_webval: 0,
    ar4_websto: 0,
    ar4_webpre: 0
};

ArticuloService.getAll = async function() {
    return api.get('/Articulo/').then(res => res.data);
}

ArticuloService.get = function() {
    return Articulo;
}

ArticuloService.consultarPorCodigo = async function(id) {
    return api.get('/Articulo/' + id).then(res => res.data);
}

ArticuloService.buscar = async function(parametros) {
    let aux = '/Articulo/Buscar?';
    //Familia nivel 1
    if (parametros.fam1 != null) { aux = aux + `&fam1=${parametros.fam1}`; }
    //Familia nivel 2
    if (parametros.fam2 != null) { aux = aux + `&fam2=${parametros.fam2}`; }
    //Familia nivel 3
    if (parametros.fam3 != null) { aux = aux + `&fam3=${parametros.fam3}`; }
    //Familia nivel 4
    if (parametros.fam4 != null) { aux = aux + `&fam4=${parametros.fam4}`; }
    //Descripcion
    if (parametros.descri != null) { aux = aux + `&query=${parametros.descri}`; }
    //Precio minimo
    if (parseFloat(parametros.precD)) { aux = aux + `&precD=${parametros.precD}`; }
    //Precio maximo
    if (parseFloat(parametros.precH)) { aux = aux + `&precH=${parametros.precH}`; }
    //Precio maximo
    if (parametros.marca != null) { aux = aux + `&marca=${parametros.marca}`; }
    //Ordenar por
    if (parametros.ord_by != null) { aux = aux + `&ord_by=${parametros.ord_by}`; }
    //ordenar de
    if (parametros.ord_dir != null) { aux = aux + `&ord_dir=${parametros.ord_dir}`; }
    return api.get(aux).then(res => res.data);
}

ArticuloService.consultarEquivalentes = async function(id) {
    return api.get('/Articulo/Equivalentes/' + id).then(res => res.data);
}

export default ArticuloService;