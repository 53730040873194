<template>
  <section>
    <!-- <loading v-bind:dialog="isLoading" v-bind:texto="'Buscando artículos en la base de datos'"></loading> -->
    <v-layout row wrap>
      <!-- <v-flex xs12>
        <p class="texto-infomativo">
          <span class="title font-weight-light">Buscar artículos:</span>
          Complete algún criterio de búsqueda y luego presione la lupa (buscar) 
          para encontrar los artículos asociados. No es obligatorio completar todos 
          los campos.
        </p>
      </v-flex> -->
      <v-flex xs12 class="marca-top-scroll">
        <v-form ref="form" v-on:submit.prevent="Buscar">
          <!-- filtro fila 1 -->
          <v-layout row wrap>
            <!-- Categorias -->
            <v-flex xs12 sm4 lg4 xl4 v-if="param_modoCaracteristicas == 2">
              <label class="v-label theme--light label-v-text-field"
                >Categorías</label
              >
              <arbolSelectFamilia></arbolSelectFamilia>
            </v-flex>

            <v-flex xs12 sm4 lg4 xl4>
              <v-select
                v-model="parametrosBusqueda.marca"
                :items="marcasFiltro"
                label="Marca"
                item-text="marcas_descripcion"
                item-value="marcas_codigo"
              ></v-select>
            </v-flex>

            <v-flex xs12 sm4 lg4 xl4>
              <v-text-field
                label="Producto"
                placeholder="Ingrese el código o nombre de producto"
                :rules="descripcionRules"
                v-model="parametrosBusqueda.descri"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <!-- filtro fila busqueda avanzada -->
          <v-layout row wrap v-show="isFiltroAvanzado">
            <v-flex xs12 sm6 lg3 xl2>
              <v-text-field
                label="Precio Minímo"
                placeholder="Ingrese el precio minímo"
                v-model="parametrosBusqueda.precD"
                type="number"
                prefix="$"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 lg3 xl2>
              <v-text-field
                label="Precio Maximo"
                placeholder="Ingrese el precio maximo"
                v-model="parametrosBusqueda.precH"
                type="number"
                prefix="$"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 lg3 xl2>
              <v-select
                v-model="parametrosBusqueda.ord_by"
                :items="Itemorden"
                label="Ordenar por"
                item-text="descri"
                item-value="cod"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 lg3 xl2>
              <v-select
                v-model="parametrosBusqueda.ord_dir"
                :items="direccionesorden"
                label="Ordenar de"
                item-text="descri"
                item-value="cod"
              ></v-select>
            </v-flex>
          </v-layout>

          <!--botones filtro-->
          <v-layout row wrap>
            <v-flex xs6 sm2 lg4 xl4>
              <v-flex class="d-flex flex-row">
                <!-- btn buscar-->
                <v-btn
                  type="submit"
                  outline
                  block
                  :disabled="isLoading"
                  title="Buscar artículos"
                >
                  <v-icon>search</v-icon> Buscar
                </v-btn>

                <!-- btn cancelar -->
                <v-btn
                  color="orange darken-2"
                  dark
                  block
                  :disabled="isLoading"
                  title="Limpiar busqueda"
                  @click="recargarPagina()"
                >
                  <v-icon>cleaning_services</v-icon> Limpiar
                </v-btn>

                <!-- btn filtro avanzado -->
                <v-btn
                  color="info"
                  block
                  :disabled="isLoading"
                  title="Búsqueda avanzada"
                  @click="togleFiltroAvanzado()"
                >
                  <v-icon v-if="!isFiltroAvanzado">zoom_in</v-icon>
                  <v-icon v-else-if="isFiltroAvanzado">zoom_out</v-icon>
                  Avanzada
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>

      <v-flex
        xs12
        v-bind:md9="param_modoCaracteristicas == 1"
        v-bind:lg9="param_modoCaracteristicas == 1"
      >
        <alert class="cuadro-mensaje"
          v-bind:tipo="notificacion.tipo"
          v-bind:mensaje="notificacion.mensaje"
        ></alert>
        <v-spacer></v-spacer>

        <v-card
          ><!-- v-show="!isLoading"> -->
          <v-toolbar card color="white">
            <v-text-field
              flat
              solo
              disabled
              label="Listado de articulos"
              single-line
              hide-details
            ></v-text-field>

            <v-layout row wrap>
              <v-flex xs6 sm4 lg4>
                <v-icon color="error">assessment</v-icon> Sin Stock
              </v-flex>
              <v-flex xs6 sm4 lg4>
                <v-icon color="warning">assessment</v-icon>Stock Critico
              </v-flex>
              <v-flex xs6 sm4 lg4>
                <v-icon color="success">assessment</v-icon>Stock Disponible
              </v-flex>
            </v-layout>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout v-resize="onResize" column>
              <v-data-table
                :headers="headers"
                :items="articulos"
                :hide-headers="isMobile"
                :class="{ mobile: isMobile }"
                :rows-per-page-items="rowsperpageitems"
                :loading="isLoading"
                :loading-text="'Buscando artículos en la base de datos'"
              >
                <template slot="items" slot-scope="props">
                  <tr v-if="!isMobile">
                    <td>{{ props.item.art_articu }}</td>
                    <td>{{ props.item.art_descri }}</td>
                    <td>
                      <span v-if="props.item.ar4_websto == 0">Consultar</span>
                      <visualizarStock
                        v-bind:tipo="props.item.stockcritico"
                        v-else
                      ></visualizarStock>
                    </td>
                    <td>
                      <span v-if="props.item.ar4_webpre == 0">Consultar</span>
                      <span v-else>{{ props.item.precioFinal | moneda }}</span>
                    </td>
                    <td>
                      <v-btn class="mx-2" fab small dark color="indigo" 
                              title="Consultar equivalentes"
                              @click="{CargarEquivalentes(props.item.art_codigo)}">
                        <v-icon>swap_horiz</v-icon>
                      </v-btn>
                    </td>
                    <td class="notbreak">
                      <addItem v-bind:art="props.item"></addItem>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>
                      <ul class="flex-content">
                        <li class="flex-item" data-label="Cod. Corto">
                          {{ props.item.art_articu }}
                        </li>
                        <li class="flex-item" data-label="Stock">
                          <span v-if="props.item.ar4_websto == 0"
                            >Consultar</span
                          >
                          <visualizarStock
                            v-bind:tipo="props.item.stockcritico"
                            v-else
                          ></visualizarStock>
                        </li>
                        <li class="flex-item" data-label="Precio">
                          <span v-if="props.item.ar4_webpre == 0"
                            >Consultar</span
                          >
                          <span v-else>{{
                            props.item.precioFinal | moneda
                          }}</span>
                        </li>
                        <li class="flex-item" data-label="Producto">
                          {{ props.item.art_descri }}
                        </li>
                        <li class="flex-item" data-label="Equivalentes">
                          ver eq
                        </li>
                        <li class="flex-item" data-label="Agregar al carrito">
                          <addItem
                            v-bind:art="props.item"
                            v-bind:isMobile="isMobile"
                          ></addItem>
                        </li>

                        <li class="enter"></li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md3 lg3 hidden-sm-and-down v-if="param_modoCaracteristicas == 1"
        ><!-- v-show="!isLoading" >-->

        <!-- <v-breadcrumbs class="bc-articulos right-none" :items="inicio">
          <template v-slot:item="props">
            <a @click="VaciarFamilia()">{{
              props.item.descri.toUpperCase()
            }}</a>
            <li class="v-breadcrumbs__divider">/</li>
          </template>
        </v-breadcrumbs> -->
        <a v-if="familias.length > 0" @click="VaciarFamilia()"> CATEGORÍAS </a>
        <v-breadcrumbs row class="bc-articulos" :items="familias">
          <template v-slot:item="props">
            <a @click="SetFamilia(props.item)">{{
              props.item.fam_descripcion.toUpperCase()
            }}</a>
          </template>
        </v-breadcrumbs>

        <listarFamilia></listarFamilia>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import DataTableMixin from "@/mixins/DataTableMixin";
import articuloservice from "@/services/ArticuloServices";
import marcaService from "@/services/MarcaServices";
import listarFamilia from "@/components/familia/listarFamilia.vue";
import arbolSelectFamilia from "@/components/familia/arbolSelectFamilia.vue";
import familianivelservice from "@/services/FamiliaNivelServices";
import visualizarStock from "@/components/articulo/visualizarStock.vue";
import alert from "@/components/alert/alert.vue";
import addItem from "@/components/pedidos/addItem.vue";
import utils from "@/plugins/Utils.js";

export default {
  name: "ConsultarArticulos",
  mixins: [DataTableMixin, utils.mixins],
  components: {
    alert,
    listarFamilia,
    arbolSelectFamilia,
    visualizarStock,
    addItem,
  },
  data() {
    return {
      param_modoCaracteristicas: 2, //1: caracteristicas al costado; 2: caracteristicas en select
      articulos: [],
      art_equiv: [],
      isLoading: false,
      isAgregar: false,
      isFiltroAvanzado: false,
      notificacion: { mensaje: "", tipo: "error" },
      isMobile: false,
      familias: [],
      familiasTreeSelect: [],
      articulo: { codart: "", cantidad: "" },
      marcasFiltro: [],
      descripcionRules: [
        function (v) {
          if (v) {
            if (v.length < 3) {
              return "Ingrese una descripción con 3 o más caracteres.";
            }
          }
          return true;
        },
      ],
      headers: [
        {
          text: "Cod. Corto",
          align: "left",
          value: "art_articu",
          sortable: false,
          width: 110,
        },
        {
          text: "Producto",
          align: "left",
          value: "art_descri",
          sortable: true,
        },
        { text: "Stock", value: "sto_stoact", sortable: false, width: 100 },
        { text: "Precio", value: "precioFinal", sortable: false, width: 100 },
        { text: "Equival.", value: "-", sortable: false, width: 100 },
        { text: "Agregar al Carrito", value: "-", sortable: false, width: 100 },
      ],
      parametrosBusqueda: {
        descri: null,
        precD: null,
        precH: null,
        marca: null,
        ord_by: "art_descri",
        ord_dir: "ASC",
        fam1: null,
        fam2: null,
        fam3: null,
        fam4: null,
      },
      direccionesorden: [
        { cod: "ASC", descri: "menor a mayor" },
        { cod: "DESC", descri: "mayor a menor" },
      ],
      Itemorden: [
        { cod: "art_descri", descri: "Descripción" },
        { cod: "precioFinal", descri: "Precio" },
      ],
    };
  },
  props: {
    inicio: {
      type: Array,
      default: () => [{ descri: "ARTICULOS" }],
    },
  },
  created() {
    if (this.param_modoCaracteristicas == 1) {
      this.RemoveUltimaFamiliaNivel();
      this.SelectFamiliaNivel();
    }
    this.llenarFiltroMarcas();
    this.$bus.$on("selectTreeFamilianivelChange", (data) => {
      this.familiasTreeSelect = data;
    });
  },
  methods: {
    scrollToError() {
      //esto se puede mejorar a una funcion mostrar error asi siempre hace scroll
      //const el = this.$el.getElementsByClassName('cuadro-mensaje')[0];
      const el = this.$el.getElementsByClassName('marca-top-scroll')[0];
      
      if (el) {
        el.scrollIntoView({behavior: 'smooth', block: 'nearest'});
      }
    },
    Cargar() {
      this.isLoading = true;
      articuloservice
        .get()
        .then((res) => {
          this.articulos = res;
        })
        .catch((err) => {
          this.notificacion.tipo = "error";
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    CargarEquivalentes(id) {
      this.isLoading = true;
      articuloservice
        .consultarEquivalentes(id)
        .then((res) => {
          if(res.length==0){
            this.notificacion.tipo = "warning";
            this.notificacion.mensaje = "No se encontraron artículos relacionados a este."
            this.scrollToError();
          }else{
            //this.art_equiv = res;
            //lo mejor seria pasarle el vector recien consultado.. pero como no me sale que se vuelva a cargar
            this.$router.push({ name: 'ArticulosEquivalentes' , params: { idArt: id } });
            //window.location.href = '#/Articulo/Equivalentes/'+id;
          }
        })
        .catch((err) => {
          this.notificacion.tipo = "error";
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
          this.scrollToError();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    SelectFamiliaNivel() {
      this.$bus.$on("setFamiliaNivel", (familia) => {
        this.familias.push(familia);
        this.Buscar();
      });
    },
    RemoveUltimaFamiliaNivel() {
      this.$bus.$on("removeUltimaFamiliaNivel", () => {
        this.familias.pop();
        if (this.familias.length === 0) {
          this.familias = [];
          this.articulos = [];
        } else {
          this.Buscar();
        }
      });
    },
    SetFamilia(familia) {
      //Si esta cargando bloqueo la opcion de bajar de nivel
      if (this.isLoading) {
        return false;
      }

      let indice = this.familias.indexOf(familia);
      let longitud = this.familias.length;

      //si hizo clic en el ultimo elemento no hago nada
      if (indice != longitud - 1) {
        this.familias.splice(indice + 1, longitud - indice);
        this.$bus.$emit("BajarFamiliaNivel", this.familias[indice]);
        this.Buscar();
      }
    },
    VaciarFamilia() {
      //Si esta cargando bloqueo la opcion de bajar de nivel
      if (this.isLoading) {
        return false;
      }

      this.familias = [];
      this.$bus.$emit("BajarFamiliaNivel", familianivelservice.Familia);
      this.articulos = [];
      return;
    },
    llenarFiltroMarcas() {
      this.isLoading = true;
      marcaService
        .getAll()
        .then((res) => {
          this.marcasFiltro = res;
          this.marcasFiltro.unshift({
            marcas_codigo: null,
            marcas_descripcion: "Ninguna marca seleccionada",
          });
        })
        .catch((err) => {
          this.notificacion.tipo = "error";
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar las marcas.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    Buscar() {
      /* Verificamos que se cumplan las validaciones */
      if (this.$refs.form) {
        if (!this.$refs.form.validate()) {
          return false;
        }
      }
      /* Se quito la busqueda de todos los productos */
      let existefiltro = !this.parametrosBusqueda.descri ? 0 : 1;
      existefiltro += !this.parametrosBusqueda.precD ? 0 : 1;
      existefiltro += !this.parametrosBusqueda.precH ? 0 : 1;
      existefiltro += !this.parametrosBusqueda.marca ? 0 : 1;

      //normalizamos los distintos componentes para seleccionar familias a un arreglo de ids
      let array_familias = [];
      if (this.param_modoCaracteristicas == 1) {
        array_familias = this.familias.map((a) => a.fam_familia);
      } else {
        if (this.familiasTreeSelect != null && this.familiasTreeSelect != "") {
          array_familias = this.familiasTreeSelect.split("/");
        }
      }
      existefiltro += array_familias.length;

      if (existefiltro < 1) {
        this.notificacion.tipo = "warning";
        this.notificacion.mensaje =
          "Debe completar al menos uno de los siguientes filtros: ";
        this.notificacion.mensaje +=
          "Producto, Precio minímo, Precio maximo, Marca ";
        this.notificacion.mensaje +=
          "o seleccionar una categoría para buscar artículos.";
        return false;
      }

      switch (array_familias.length) {
        case 0:
          this.parametrosBusqueda.fam1 = null;
          this.parametrosBusqueda.fam2 = null;
          this.parametrosBusqueda.fam3 = null;
          this.parametrosBusqueda.fam4 = null;
          break;
        case 1:
          this.parametrosBusqueda.fam1 = array_familias[0];
          this.parametrosBusqueda.fam2 = null;
          this.parametrosBusqueda.fam3 = null;
          this.parametrosBusqueda.fam4 = null;
          break;
        case 2:
          this.parametrosBusqueda.fam1 = array_familias[0];
          this.parametrosBusqueda.fam2 = array_familias[1];
          this.parametrosBusqueda.fam3 = null;
          this.parametrosBusqueda.fam4 = null;
          break;
        case 3:
          this.parametrosBusqueda.fam1 = array_familias[0];
          this.parametrosBusqueda.fam2 = array_familias[1];
          this.parametrosBusqueda.fam3 = array_familias[2];
          this.parametrosBusqueda.fam4 = null;
          break;
        case 4:
          this.parametrosBusqueda.fam1 = array_familias[0];
          this.parametrosBusqueda.fam2 = array_familias[1];
          this.parametrosBusqueda.fam3 = array_familias[2];
          this.parametrosBusqueda.fam4 = array_familias[3];
          break;
      }

      this.isLoading = true;
      articuloservice
        .buscar(this.parametrosBusqueda)
        .then((res) => {
          //Limpiamos las notificaciones
          this.notificacion.mensaje = "";
          //Limpiamos el array articulos
          this.articulos = [];
          this.articulos = res;
        })
        .catch((err) => {
          this.notificacion.tipo = "error";
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setCar() {
      this.isAgregar = true;
    },
    togleFiltroAvanzado() {
      this.isFiltroAvanzado = !this.isFiltroAvanzado;
      this.parametrosBusqueda.precD = null;
      this.parametrosBusqueda.precH = null;
    },
  },
};
</script>
