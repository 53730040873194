<template>
  <div id="familia">
    <v-card class="elevation-0">
      <v-toolbar card dense color="transparent">
        <v-toolbar-title>
          <h4>Categorías</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-progress-linear height="2" :indeterminate="true" v-show="isLoading"></v-progress-linear>
      <v-divider></v-divider>
      <v-card-text class="pa-0" v-show="!isLoading">
        <alert v-bind:tipo="notificacion.tipo" v-bind:mensaje="notificacion.mensaje"></alert>
        <v-text-field
          v-model="buscarfamilia"
          placeholder='Buscar en el listado'
        ></v-text-field>
        <v-list class="pa-0">
          <v-list-tile 
            v-if="familiasSelect.length > 0"
            class="lista-titulo"
          >
            <v-list-tile-content>
              <a 
                title="Subir nivel" 
                @click="subirNivel()"
              >
                <v-list-tile-sub-title v-html="'Quitar filtro'"></v-list-tile-sub-title>
              </a>
            </v-list-tile-content>
          </v-list-tile>
          <template v-for="item in getFamilias">
            <v-list-tile 
              v-if="item.fam_familia != null && item.fam_familia != '--' && item.fam_descripcion != null && item.fam_descripcion.length > 0"
              :key="(item.fam_familia + item.fam_descripcion)"
              class="lista-titulo"
            >
              <v-list-tile-content>
                <a 
                  title="Seleccionar familia" 
                  @click="SelectFamiliaNivel(item)"
                >
                  <v-list-tile-sub-title v-html="item.fam_descripcion"></v-list-tile-sub-title>
                </a>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <li class="enter"></li>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import familianivelservice from "@/services/FamiliaNivelServices";
import alert from "@/components/alert/alert.vue";
export default {
  name: "ListarFamiliaNivel",
  components: { alert },
  data() {
    return {
      isLoading: false,
      notificacion: { mensaje: "", tipo: "error" },
      familias: [],
      familia: familianivelservice.Familia,
      familiasSelect: [],
      buscarfamilia: ''
    };
  },
  created() {
    this.CargarFamilia();
    this.$bus.$on("BajarFamiliaNivel", familia => {
      if (familia.fam_familia == 0) {
        this.familiasSelect = [];
        return this.CargarFamilia(); 
      }
      let indice = this.familiasSelect.indexOf(familia) + 1;
      let longitud = this.familiasSelect.length;
      this.familiasSelect.splice(indice,(longitud-indice));
      this.CargarFamiliaPorCodigo(this.familiasSelect);
    });
  },
  computed: {
    getFamilias() {
      if(!this.buscarfamilia) { return this.familias; }
      let familiasFiltradas = [];
      for (let index = 0; index < this.familias.length; index++) {
        let descri = this.familias[index].fam_descripcion;
        let posicion = -1;
        
        if(descri != null) {
          descri = descri.toLowerCase();
          posicion = descri.indexOf(this.buscarfamilia.toLowerCase());
        }
        
        if(posicion != -1) {
          familiasFiltradas.push(this.familias[index]);
        }
      }
      return familiasFiltradas;
    }
  },
  methods: {
    CargarFamilia() {
      this.familias = [];
      this.isLoading = true;
      this.notificacion.mensaje = '';
      familianivelservice
        .getAll()
        .then(res => {
          this.familias = res;
        })
        .catch(err => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar las familias.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    SelectFamiliaNivel(familia) {
      this.$bus.$emit("setFamiliaNivel", familia);

      if (this.familiasSelect.length === 4) {
        this.familiasSelect[3] = familia;
      } else {
        this.familiasSelect.push(familia);
      }
      this.CargarFamiliaPorCodigo(this.familiasSelect);
    },
    CargarFamiliaPorCodigo(familia) {
      this.familias = [];
      this.isLoading = true;
      this.notificacion.mensaje = '';
      familianivelservice
        .consultarPorCodigo(familia)
        .then(res => {
          this.familias = res;
        })
        .catch(err => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar las familias.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    subirNivel() {
      let familia = {fam_familia:0};
      this.buscarfamilia = '';
      if ((this.familiasSelect.length - 1) > 0) {
        this.familiasSelect.pop();
        let longitud = this.familiasSelect.length - 1;
        familia = this.familiasSelect[longitud];
      }
      this.$bus.$emit("BajarFamiliaNivel", familia);
      this.$bus.$emit("removeUltimaFamiliaNivel");
    }
  }
};
</script>

<style scoped>
  .enter{
    height: 15px;
  }

  .v-list__tile__sub-title, .v-list__tile__title {
    white-space: normal;
  }
</style>