import api from './api';

const FamiliaNivelService = {};
let Familia = {
    fam_familia: '0',
    fam_descripcion: '',
};

FamiliaNivelService.Familia = Familia;


FamiliaNivelService.getAll = async function() {
    return api.get('/FamiliaNivel/').then(res => res.data);
}

/*
 * esta funcion es inusable pero no puedo eliminarla aun
 */
FamiliaNivelService.consultarPorCodigo = async function(familias) {
    let aux = '/FamiliaNivel'  //la barra se agrega en el for de abajo

    for (let index = 0; index < familias.length; index++) {
        aux += '/' + familias[index].fam_familia;
    }

    return api.get(aux).then(res => res.data);
}

/*
 * esta funcion recibe un array de codigos y arma la url para consultar 
 */
FamiliaNivelService.consultarPorArray = async function(array_codigos) {
    let aux = '/FamiliaNivel'  //la barra se agrega en el for de abajo

    for (let index = 0; index < array_codigos.length; index++) {
        aux += '/' + array_codigos[index];
    }

    return api.get(aux).then(res => res.data);
}

export default FamiliaNivelService;