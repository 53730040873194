<template>
    <v-icon :color='Color' title='Sin Stock' v-if="tipo == 0">assessment</v-icon>
    <v-icon :color='Color' title='Stock Critico' v-else-if="tipo == 1">assessment</v-icon>
    <v-icon :color='Color' title='Stock Disponible' v-else-if="tipo == 2">assessment</v-icon>
    <v-icon :color='Color' title='Stock Desconocido' v-else>assessment</v-icon>
</template>

<script>
export default {
    props: {
        tipo:{
            type: Number,
            required: true
        }
    },
    computed: {
        Color: function() {
            switch (this.tipo) {
                case 0:
                    return "error";
                case 1:
                    return "warning";
                case 2:
                    return "success";
                default:
                    return "secondary";
            }
        }
    }
}
</script>