<template>
  <section>
    <alert v-bind:tipo="notificacion.tipo" v-bind:mensaje="notificacion.mensaje"></alert>
    <v-form 
      ref="formaddItem" 
      v-on:submit.prevent="addItem" 
      v-show="notificacion.mensaje == ''"
      v-if="art.stockcritico != 0"
    >
      <v-text-field type="number" :rules="cantidadRules" v-model.number="cantidad"></v-text-field>
      <v-btn type="submit" block color="primary" dark>Agregar</v-btn>
    </v-form>
    <p v-else>Sin Stock</p>
  </section>
</template>

<script>
import OrdenesTrabajoTemServices from "@/services/OrdenesTrabajoTemServices";
import alert from "@/components/alert/alert.vue";
export default {
  name: "AddItem",
  components: { alert },
  props: {
    art: {
      required: true
    },
  },
  data() {
    return {
      dialog: false,
      notificacion: { mensaje: "", tipo: "error" },
      cantidad: 0,
      cantidadRules: [
        v => !!v || "Debe ingresar una cantidad mayor a cero.",
        v => v >= 0 || "El valor ingresado es menor o igual a cero.",
        v => v < 10001 || "El valor ingresado es mayor a 10000.",
      ],
    };
  },
  methods: {
    addItem() {
      //Verificamos que se cumplan las validaciones
      if (!this.$refs.formaddItem.validate()) {
        return false;
      }
      this.notificacion.mensaje = "Agregando item...";
      this.notificacion.tipo = "info";
      OrdenesTrabajoTemServices.addItem(
        this.art.art_codigo,
        this.cantidad
      )
        .then(() => {
          const aux = (localStorage.cantidadItemsCarrito) ? parseFloat(localStorage.cantidadItemsCarrito) : 0;
          localStorage.cantidadItemsCarrito = aux + parseFloat(this.cantidad);
          this.notificacion.mensaje = "Articulo agregardo correctamente";
          this.notificacion.tipo = "success";
          this.cantidad = 0;
          this.$bus.$emit("setCar");
          //Limpio las validaciones una vez que agregue el articulo para evitar confusiones, 
          //ya que muestra el error de ingresar un valor mayor a cero.
          this.$refs.formaddItem.resetValidation();
        })
        .catch(err => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar agregar el item.";
          this.notificacion.tipo = "error";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.notificacion.mensaje = "";
          }, 5000);
        });
    }
  }
};
</script>
